import { ENABLE_SHIFT_TIME_RECEPTION } from '@/constants/feature-flags';
import { WEDDING_RESEPSI_TIME } from '@/constants/index';
import useQueryString from './useQueryString';

export const SHIFT_LIST = [
  {
    value: '1',
    shift: '5.00 PM',
    name: 'Shift 1 (Wedding Reception)',
  },
  {
    value: '2',
    shift: '5.00 PM WIB',
    name: 'Shift 2 (Pre-Wedding Dinner & Wedding Reception)',
  },
];

function generateShift(guestShift) {
  const { getQueryValue } = useQueryString();
  let shift = WEDDING_RESEPSI_TIME;

  if (!ENABLE_SHIFT_TIME_RECEPTION) return WEDDING_RESEPSI_TIME;

  if (guestShift) {
    shift = SHIFT_LIST.find((s) => s.value === guestShift.toString());
  } else {
    shift = SHIFT_LIST.find((s) => s.value === getQueryValue('shift'));
  }

  return shift ? shift.shift : 'Shift not found';
}

export default generateShift;
