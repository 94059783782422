import { DEFAULT_LANGUAGE as LANG } from './feature-flags';
/**
 * ************************************************
 * Time & Language Property
 * ************************************************
 */
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone.js';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/id';
import 'dayjs/locale/en';

dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.tz.setDefault('Asia/Jakarta');

export const PASSWORD_LINK_GENERATOR = 'marryyou';
/**
 * ************************************************
 * Flag to to set Bride to BOY first
 * ************************************************
 */
export const IS_BOY_FIRST = false;

/**
 * ************************************************
 * Bride & Groom Info
 * ************************************************
 */
export const GIRL_NAME = 'Magdalena';
export const GIRL_NAME_SHORT = 'Magdalena';
export const GIRL_FATHER_NAME = `Hristin Hristov`;
export const GIRL_MOTHER_NAME = `Stanka Hristova`;
export const GIRL_PARENT_NAME =
  LANG === 'en'
    ? `The Daughter of <br/> Mr. ${GIRL_FATHER_NAME} & Mrs. ${GIRL_MOTHER_NAME}`
    : `Putri dari <br/> Bapak ${GIRL_FATHER_NAME} & <br/> Ibu ${GIRL_MOTHER_NAME}`;
// -> boy section
export const BOY_NAME = 'Aditya';
export const BOY_NAME_SHORT = 'Aditya';
export const BOY_FATHER_NAME = `Bintoro Kusumo`;
export const BOY_MOTHER_NAME = `Indria Wibisana`;
export const BOY_PARENT_NAME =
  LANG === 'en'
    ? `The Son of <br/> Mr. ${BOY_FATHER_NAME} & Mrs. ${BOY_MOTHER_NAME}`
    : `Putra dari <br/> Bapak ${BOY_FATHER_NAME} & <br/> Ibu ${BOY_MOTHER_NAME}`;
// -> bride section
export const THE_BRIDE = IS_BOY_FIRST
  ? `${BOY_NAME_SHORT} & ${GIRL_NAME_SHORT}`
  : `${GIRL_NAME_SHORT} & ${BOY_NAME_SHORT}`;

/**
 * ************************************************
 * Instagram Profile Account
 * @important please put instagram id without `@`
 * ************************************************
 */
export const IG_BOY = '';
export const IG_GIRL = '';
export const IG_FILTER = `https://www.instagram.com/ar/944860223947959/`;

/**
 * ************************************************
 * SEO Requirement
 * @important - Don't forget to update SEO IMAGE
 * ************************************************
 */
export const SEO_IMAGE = `https://ik.imagekit.io/bxwgcojbe/aditya-magdalena/seo.jpg?updatedAt=1706686519390`;
export const SEO_URL = 'https://invitato.net/';
export const SEO_TITLE = `The Wedding of ${THE_BRIDE} by Invitato`;
export const SEO_DESCRIPTION =
  LANG === 'en'
    ? `Together with joyful hearts, we re pleased to announce the beginning of this new chapter of our lives together. Please click the Website Invitation link on this message for more information details.`
    : `Menjadi sebuah kebahagiaan bagi kami untuk mengumumkan awal dari babak baru kehidupan kami bersama. Silakan klik tautan situs Undangan Website di bawah untuk informasi lebih lanjut:`;

/**
 * ************************************************
 * Time requirement for Counting Down, and
 * Remind me generator
 * @important - please convert time to Epoch time by
 * using this link https://www.epochconverter.com/
 * ************************************************
 */
export const EPOCH_START_EVENT = 1727517600;
export const EPOCH_RESEPSI_START = 1727517600;
export const EPOCH_END_EVENT = 1727532000;

/**
 * ************************************************
 * DATE REQUIREMENT
 * @important - Format date YYYY-MM-DD
 * ************************************************
 */
export const DATE_AKAD = dayjs.tz('2024-09-28');
export const DATE_RESEPSI = DATE_AKAD;

export const WEDDING_AKAD_TIME = '6.00 PM WIB';
export const WEDDING_AKAD_LOC_NAME = 'Tbc';
export const WEDDING_AKAD_LOC_ROAD = `Tbc`;
export const WEDDING_AKAD_DRESSCODE = ``;
export const WEDDING_AKAD_FULLDATE = dayjs(DATE_AKAD).locale(LANG).format('dddd, DD MMMM YYYY');

export const WEDDING_RESEPSI_TIME = '5.00 PM WIB (Cocktail Hour) & 7.00 PM WIB (Dinner)';
export const WEDDING_RESEPSI_LOC_NAME = `Shangri-La Hotel Surabaya`;
export const WEDDING_RESEPSI_LOC_ROAD = `Jl. Mayjen Sungkono No. 120, Pakis, Kec. Sawahan, Surabaya, Jawa Timur 60256`;
export const WEDDING_RESEPSI_DRESSCODE = `Semi Formal`;
export const WEDDING_RESEPSI_FULLDATE = dayjs(DATE_RESEPSI)
  .locale(LANG)
  .format('dddd, DD MMMM YYYY');
export const WEDDING_RESEPSI_DATE = WEDDING_AKAD_FULLDATE;
// export const WEDDING_RESEPSI_DATE = dayjs(DATE_RESEPSI)
//   .locale(DEFAULT_LANGUAGE)
//   .format('DD • MM • YYYY');

/**
 * ********************************************************
 * Link Generator V2
 * @important - this info will be applied at link generator
 * ********************************************************
 */
export const HOSTNAME = 'https://magdalenandaditya.com/';
export const BROADCAST_WEDDING_LOCATION = `Shangri-La Hotel Surabaya`;
export const BROADCAST_WEDDING_DAY = {
  id: dayjs(DATE_RESEPSI).locale('id').format('dddd, DD MMMM YYYY'),
  en: dayjs(DATE_RESEPSI).locale('en').format('dddd, DD MMMM YYYY'),
};

/**
 * ************************************************
 * Maps Location
 * ************************************************
 */
export const GOOGLE_MAPS_LINK = `https://maps.app.goo.gl/HA1xRZTm4N2gftes6`;
export const GOOGLE_MAPS_ADDRESS = `Shangri-La Surabaya`;

/**
 * ************************************************
 * Backsound Copyright
 * ************************************************
 */
export const SOUND_BY = `Paul Partohap - P.S. I LOVE YOU`;
export const SOUND_URL = 'https://youtu.be/n5i3QRvPA9Y?si=eJGcEtO7NCCli1iL';

/**
 * ************************************************
 *  Youtube Live requirement
 * ************************************************
 */
export const YOUTUBE_LINK = 'https://youtu.be/ApX0LaS6gSM';
export const YOUTUBE_EMBED = 'https://www.youtube.com/embed/ApX0LaS6gSM';
export const YOUTUBE_PREWEDDING_EMBED = `https://www.youtube.com/embed/WEC5RezD5jU`;
export const YOUTUBE_PREWEDDING_LINK = `https://youtu.be/WEC5RezD5jU`;

/**
 * ************************************************
 *  Invitato Link Requirement
 * ************************************************
 */
export const URL_INVITATO = 'https://invitato.net';
export const URL_WA_INVITATO = 'https://wa.me/+628112778189?text=Hi%20Kak,%20aku%20mau%20pesan%20undangan%20online%20nih!';